import React from "react"
import {
  DiReact,
  DiHtml5,
  DiJsBadge,
  DiBootstrap,
  DiCss3,
  DiSass,
  DiGit,
  DiPhp,
  DiCodeigniter,
  DiMysql,
  DiGoogleAnalytics,
  DiJqueryLogo,
} from "react-icons/di"
import { FaVuejs } from "react-icons/fa"
import {
  SiTypescript,
  SiFramer,
  SiSonarqube,
  SiNextdotjs,
  SiVisualstudio,
  SiWindows,
  SiLinux,
  SiGitlab,
  SiGithub,
  SiAsana,
  SiSentry,
  SiTailwindcss,
  SiFigma,
  SiNotion,
  SiMiro,
} from "react-icons/si"
import { GrGatsbyjs, GrThreeDEffects } from "react-icons/gr"

export default function tech() {
  return {
    skills: [
      {
        icon: <DiGit />,
        value: 3,
        content: "Git",
      },
      {
        icon: <DiHtml5 />,
        value: 4.5,
        content: "Html",
      },
      {
        icon: <DiJsBadge />,
        value: 4,
        content: "Javascript",
      },
      {
        icon: <DiReact />,
        value: 4,
        content: "React",
      },
      {
        icon: <GrGatsbyjs />,
        value: 3,
        content: "Gatsby",
      },
      {
        icon: <SiNextdotjs />,
        value: 2,
        content: "Next",
      },
      {
        icon: <FaVuejs />,
        value: 2,
        content: "Vue",
      },
      {
        icon: <DiReact />,
        value: 2,
        content: "React Native",
      },
      {
        icon: <SiTypescript />,
        value: 2,
        content: "Typescript *",
      },
      {
        icon: <DiJqueryLogo />,
        value: 2,
        content: "Jquery",
      },

      {
        icon: <DiCss3 />,
        value: 4,
        content: "Css",
      },
      {
        icon: <DiSass />,
        value: 3,
        content: "Sass",
      },
      {
        icon: <DiBootstrap />,
        value: 3,
        content: "Bootstrap",
      },
      {
        icon: <SiTailwindcss />,
        value: 3,
        content: "Tailwind",
      },
      {
        icon: <DiPhp />,
        value: 4,
        content: "Php",
      },
      {
        icon: <DiCodeigniter />,
        value: 4,
        content: "Codeigniter",
      },
      {
        icon: <DiMysql />,
        value: 3,
        content: "MySql",
      },
      {
        icon: <DiGoogleAnalytics />,
        value: 2,
        content: "Seo & Analytics",
      },
      {
        icon: <SiFramer />,
        value: false,
        content: "Motion *",
      },
      {
        icon: <GrThreeDEffects />,
        value: false,
        content: "3D Visual *",
      },
    ],
    tools: [
      {
        name: "OS",
        list: [
          {
            icon: <SiWindows />,
            content: "Windows",
          },
          {
            icon: <SiLinux />,
            content: "Linux",
          },
        ],
      },
      {
        name: "Source Control",
        list: [
          {
            icon: <SiGithub />,
            content: "GitHub",
          },
          {
            icon: <SiGitlab />,
            content: "GitLab",
          },
        ],
      },
      {
        name: "Task manager",
        list: [
          {
            icon: <SiAsana />,
            content: "Asana",
          },
          {
            icon: <SiNotion />,
            content: "Notion",
          },
        ],
      },
      {
        name: "Design",
        list: [
          {
            icon: <SiFigma />,
            content: "Figma",
          },
          {
            icon: <SiMiro />,
            content: "Miro",
          },
        ],
      },
      {
        name: "IDE",
        list: [
          {
            icon: <SiVisualstudio />,
            content: "VsCode",
          },
        ],
      },
      {
        name: "Reporting",
        list: [
          {
            icon: <SiSentry />,
            content: "Sentry",
          },
        ],
      },
      {
        name: "Testing",
        list: [
          {
            icon: <SiSonarqube />,
            content: "SonarQube",
          },
        ],
      },
    ],
  }
}
