import React, { Component } from "react"
import { DiGithubBadge } from "react-icons/di"
import { GrLinkedinOption } from "react-icons/gr"
import { BsStarFill, BsStarHalf, BsStar, BsPaperclip } from "react-icons/bs"
import Tilt from "react-parallax-tilt"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import CV from "../assets/file/CV.pdf"
import tech from "../assets/contents/tech"

import "../assets/css/about.scss"
export default class about extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handlingSkills = (index, status) => {
    let skills = []

    if (index % 1 === 0) {
      // int
      for (let i = 0; i < 5; i++) {
        if (i < index) {
          skills.push(<BsStarFill />)
        } else {
          skills.push(<BsStar />)
        }
      }
    } else {
      // float
      let str = String(index).split(".")
      for (let i = 0; i < 5; i++) {
        if (i < parseInt(str[0])) {
          skills.push(<BsStarFill />)
        } else if (i === parseInt(str[0])) {
          skills.push(<BsStarHalf />)
        } else {
          skills.push(<BsStar />)
        }
      }
    }

    return skills
  }

  render() {
    const { skills, tools } = tech()

    let allSkills
    if (skills) {
      allSkills = skills.map((skill, i) => {
        return (
          <Tilt
            key={skill.content}
            className="parallax-effect"
            perspective={500}
          >
            <div className="inner-element">
              <div className="skill">
                {skill.icon}
                <p>{skill.content}</p>
                {/* <div className='skill-value'>
                                    {
                                        skill.value ?
                                            this.handlingSkills(skill.value) :
                                            <div className='skill-progress'>
                                                <div className='skill-progress-bar'>

                                                </div>
                                            </div>
                                    }
                                </div> */}
              </div>
            </div>
          </Tilt>
        )
      })
    }

    let allTools
    if (tools) {
      allTools = tools.map((tool, i) => {
        let lists
        if (tool.list.length > 0) {
          lists = tool.list.map((ls, i) => {
            return (
              <li key={ls.content}>
                <div>
                  {ls.icon} {ls.content}
                </div>
              </li>
            )
          })
        }
        return (
          <Tilt key={tool.name} className="parallax-effect" perspective={500}>
            <div className="inner-element">
              <div className="tool">
                <p>{tool.name}</p>
                <ul>{lists}</ul>
              </div>
            </div>
          </Tilt>
        )
      })
    }
    return (
      <Layout>
        <main className="page">
          <div className="about-content">
            <div className="about-description">
              <div>
                <h1>- About me</h1>
                <p>
                  Bachelor's Degree from Informatics Management with more than 5
                  years of active become developer application-based web and
                  desktop, having knowledge both backend and frontend thing,
                  lately have more interest in Developing Website as Frontend
                  Developer, someone who fully passionate on programming,
                  trustworthy partner, responsible, action and process-oriented.
                </p>
              </div>
              <div>
                <h2>- More about me</h2>
                <div className="about-file">
                  <a
                    href={CV}
                    download="CV-angga-hermawan"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="CV angga hermawan"
                  >
                    <BsPaperclip />
                  </a>
                  <a
                    href="https://github.com/anggaggaH"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="github angga hermawan"
                  >
                    <DiGithubBadge />
                  </a>
                  <a
                    href="http://bit.ly/Ln-anggaH"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="linkedin angga hermawan"
                  >
                    <GrLinkedinOption />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className='about-latest'>
                            <h2>- Latest Jobs</h2>

                        </div> */}
            <div className="about-skills">
              <h2>- Tech Stack</h2>
              <div className="skills">{allSkills}</div>
            </div>
            <div className="about-tools">
              <h2>- Tools</h2>
              <div className="tools">{allTools}</div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export const Head = props => (
  <Seo
    title="About me | Angga Hermawan"
    description={`Angga Hermawan is fullstack developer who fully passionate on programming with more than 5 years active become app and web developer`}
    pathname={props.location?.pathname}
  />
)
